import React, { useState } from 'react';
import {
    Box,
    Collapse,
    FormControl,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    NativeSelect,
    PopoverOrigin,
    Select,
    SelectChangeEvent,
    SxProps,
    Theme
} from '@mui/material';
import { ArrowDropDown, Clear } from '@mui/icons-material';
import useMyDropdowns from 'hooks/useMyDropdowns';
import { GridSingleSelect } from 'ui-component/formComponents';

export interface EntitiesAssociatedDropdownProps {
    onChange: (value: string | string[]) => void;
    recordTypeId: number;
    initialValue?: string | string[];
    label?: string;
    native?: boolean;
    fromFilterPanel?: boolean;
    multiple?: boolean;
    PopoverSx?: SxProps<Theme> | undefined;
    PopoverPropsAnchorOrigin?: PopoverOrigin | undefined;
    PopoverPropsTransformOrigin?: PopoverOrigin | undefined;
}

export const EntitiesAssociatedDropdown = ({
    recordTypeId,
    onChange,
    initialValue,
    native,
    label,
    fromFilterPanel,
    multiple,
    PopoverSx,
    PopoverPropsAnchorOrigin,
    PopoverPropsTransformOrigin
}: EntitiesAssociatedDropdownProps) => {
    const [selectedTenant, setSelectedTenant] = useState<string>('');
    const [entityMultipleSelect, setEntityMultipleSelect] = useState<string[]>([]);

    const { loading, allBusinessEntities } = useMyDropdowns({
        recordTypeId,
        dropdowns: ['ALL_BUSINESS_ENTITIES'],
        onCompleted(data) {
            const list = data.allBusinessEntities;
            if (list && initialValue) {
                setSelectedTenant(String(initialValue) || '');
            }
        }
    });

    const handleValueChange = (event: SelectChangeEvent<string | string[]> | React.ChangeEvent<{ value: string }>) => {
        const newValue = event.target.value;
        if (multiple) setEntityMultipleSelect(newValue as string[]);
        else setSelectedTenant(newValue as string);
        onChange(newValue);
    };

    const handleClearClick = () => {
        const newValue = ['0'];
        setEntityMultipleSelect(newValue as string[]);
        onChange(newValue);
    };

    return (
        <Box sx={{ minWidth: 149, width: '100%' }}>
            <FormControl fullWidth disabled={loading}>
                {label && (
                    <InputLabel id="record-status-select" shrink>
                        {label}
                    </InputLabel>
                )}

                {fromFilterPanel && multiple && (
                    <Select
                        value={entityMultipleSelect}
                        onChange={handleValueChange}
                        multiple
                        sx={{ pt: '3px !important' }}
                        endAdornment={
                            <IconButton
                                sx={{
                                    display: 'none'
                                }}
                                onClick={handleClearClick}
                            >
                                <Clear />
                            </IconButton>
                        }
                    >
                        {allBusinessEntities?.map((el) => (
                            <MenuItem key={el.id} value={el.id}>
                                {el.displayName}
                            </MenuItem>
                        ))}
                    </Select>
                )}

                {fromFilterPanel && native && (
                    <NativeSelect value={selectedTenant} onChange={handleValueChange}>
                        <option value=""> &nbsp;</option>
                        {allBusinessEntities?.map((el) => (
                            <option key={el.id} value={el.displayName}>
                                {el.displayName}
                            </option>
                        ))}
                    </NativeSelect>
                )}

                {!fromFilterPanel && (
                    <GridSingleSelect
                        width="100%"
                        onChange={(value) =>
                            handleValueChange({ target: { value: String(value) } } as SelectChangeEvent<string | string[]>)
                        }
                        options={
                            allBusinessEntities
                                ?.filter((el) => el.enabled)
                                .map((el) => ({
                                    id: el.id,
                                    name: el.displayName
                                })) || []
                        }
                        // listFitContent
                        value={
                            allBusinessEntities.find((el) => el.displayName === selectedTenant || Number(el.id) === Number(selectedTenant))
                                ?.id || ''
                        }
                        leftIcon={<ArrowDropDown />}
                        sx={{
                            borderRadius: '8px',
                            '&:hover': { bgcolor: '#EBEEFE' },
                            bgcolor: '#F5F6F7'
                        }}
                        PopoverSx={PopoverSx}
                        PopoverPropsAnchorOrigin={PopoverPropsAnchorOrigin}
                        PopoverPropsTransformOrigin={PopoverPropsTransformOrigin}
                    />
                )}
                <Collapse in={loading}>
                    <LinearProgress />
                </Collapse>
            </FormControl>
        </Box>
    );
};
