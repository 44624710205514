import { gql } from '@apollo/client';

export const QUERY_FIND_ALL_BUSINESS_ENTITIES = gql`
    query FindAllBusinessEntities {
        findAllBusinessEntities {
            id
            displayName
            enabled
        }
    }
`;
