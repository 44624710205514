import { Box, CircularProgress, Fab, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { Close, Domain, Edit, Save } from '@mui/icons-material';
import { PanelTitle } from '../utils';
import { ITenant } from 'types/tenant';
import { useTheme } from '@mui/styles';
import { useState } from 'react';
import { EntitiesAssociatedDropdown } from 'ui-component/records/dropdowns/EntitiesAssociatedDropdown';
import { useMutation } from '@apollo/client';
import { MUTATION_UPDATE_RECORD_HEADER } from 'graphql/mutations/bills';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';

export type FooterProps = {
    panelState: PanelTitle;
    onEdit: () => void;
    onSave?: () => Promise<void>;
    onCancel?: () => void;
    isCreate?: boolean;
    disabledButtons?: boolean;
    isRecordView?: boolean;
    businessEntity?: Pick<ITenant, 'id' | 'name'> | null | undefined;
    recordTypeId?: number;
    recordDataId?: number;
};

const FooterOptions = ({
    panelState,
    onEdit,
    onSave,
    onCancel,
    isCreate,
    disabledButtons,
    isRecordView = true,
    businessEntity,
    recordTypeId,
    recordDataId
}: FooterProps) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(businessEntity?.id);

    const theme = useTheme();
    const storeDispatch = useDispatch();

    const [updateRecordHeader] = useMutation<{ updateRecordHeader: any }>(MUTATION_UPDATE_RECORD_HEADER);

    const handleOnSave = async () => {
        await onSave?.();
    };

    const handleOnCancel = () => {
        onCancel?.();
    };

    const handleOnEntity = () => {
        setShowDropdown((value) => !value);
    };

    const handleOnSaveEntity = async (entity: string) => {
        try {
            await updateRecordHeader({
                variables: {
                    data: {
                        id: Number(recordDataId),
                        businessEntity: Number(entity)
                    }
                }
            });

            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Data saved!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (err: any) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
        setShowDropdown(false);
    };

    return (
        <Box>
            <Box sx={{ position: 'relative' }} gap={1} mb={1}>
                {showDropdown && (
                    <Box
                        sx={{
                            position: 'absolute',
                            zIndex: 1,
                            right: 50,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: 1
                        }}
                    >
                        <Paper elevation={1}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 0.5, width: 376 }}>
                                <Typography variant="caption" sx={{ p: 1 }}>
                                    Entity:
                                </Typography>

                                <EntitiesAssociatedDropdown
                                    recordTypeId={Number(recordTypeId)}
                                    onChange={(value) => {
                                        setSelectedEntity(value as string);
                                        handleOnSaveEntity(value as string);
                                    }}
                                    initialValue={selectedEntity}
                                    PopoverSx={{
                                        top: '-15px',
                                        left: '-70px'
                                    }}
                                    PopoverPropsAnchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }}
                                    PopoverPropsTransformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    }}
                                />
                            </Box>
                        </Paper>
                    </Box>
                )}
                <IconButton
                    onClick={handleOnEntity}
                    size="medium"
                    sx={{
                        color: theme.palette.primary[400],
                        bgcolor: showDropdown ? theme.palette.secondary.light : `${theme.palette.primary[300]} !important`,
                        fontWeight: 600,
                        height: '100%',
                        '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                    }}
                >
                    <Domain />
                </IconButton>
            </Box>

            {panelState === PanelTitle.View && isRecordView && (
                <Tooltip title="Edit">
                    <Fab color="secondary" onClick={onEdit} sx={{ width: 40, height: 40 }} disabled={disabledButtons}>
                        <Edit />
                    </Fab>
                </Tooltip>
            )}

            {panelState === PanelTitle.Edit && isRecordView && (
                <Box gap={1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {!isCreate && (
                        <Tooltip title="Discard Changes">
                            <Fab onClick={handleOnCancel} sx={{ width: 40, height: 40, boxShadow: 'none', '& svg': { color: '#858585' } }}>
                                <Close />
                            </Fab>
                        </Tooltip>
                    )}
                    <Tooltip title="Save Changes">
                        <Fab
                            color="secondary"
                            onClick={handleOnSave}
                            sx={{ width: 40, height: 40, boxShadow: 'none' }}
                            disabled={disabledButtons}
                        >
                            {disabledButtons ? <CircularProgress size={20} color="inherit" /> : <Save />}
                        </Fab>
                    </Tooltip>
                </Box>
            )}

            {panelState === PanelTitle.View && !isRecordView && (
                <Tooltip title="Save">
                    <Fab color="secondary" onClick={onEdit} sx={{ width: 40, height: 40 }} disabled={disabledButtons}>
                        <Save />
                    </Fab>
                </Tooltip>
            )}
        </Box>
    );
};

export default FooterOptions;
