import { Avatar, Box, Chip, Typography } from '@mui/material';
import { AuditLog, CommentLog, LineItemLog } from 'ui-component/RecordView/types';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { Circle } from '@mui/icons-material';
import { useMemo } from 'react';
import colors from 'themes/orderbahnColors';

export type LogItemProps = {
    item: AuditLog | CommentLog | LineItemLog;
    onOpen: () => void;
    striped?: boolean;
    position: number | null;
    currentPosition: number | null;
};

export const LogItem = ({ onOpen, item, striped, position, currentPosition }: LogItemProps) => {
    const bgcolor = useMemo(() => {
        if (position === currentPosition) {
            return colors.light.secondary.light;
        }

        if (striped) {
            return 'white';
        }

        return undefined;
    }, [position, currentPosition, striped]);

    return (
        <Box
            onClick={onOpen}
            sx={(theme) => ({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '1rem',
                gap: '16px',
                cursor: 'pointer',
                bgcolor,
                '&:hover': {
                    color: theme.palette.primary[400],
                    background: theme.palette.primary[300]
                }
            })}
        >
            <Circle color="secondary" fontSize="small" />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'start'
                }}
            >
                <Box>
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.secondary.main,
                            fontWeight: '700',
                            fontSize: '12px',
                            lineHeight: '19.9px',
                            letterSpacing: '0.4px'
                        })}
                    >
                        {item.datetime ? format(new Date(item.datetime), 'MMM d, y - h:mmaaaa') : ''}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    <Box>
                        <Avatar
                            sx={(theme) => ({
                                bgcolor: '#FFF',
                                border: `1px solid ${theme.palette.secondary.main}`,
                                width: 24,
                                height: 24,
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center'
                            })}
                        >
                            {item.user.firstName ? item.user.firstName.charAt(0) : ''}
                        </Avatar>
                    </Box>
                    <Box>
                        <Typography
                            sx={(theme) => ({
                                color: '#54595E',
                                fontWeight: '500',
                                fontSize: '14px',
                                lineHeight: '21.98px',
                                letterSpacing: '0.1px'
                            })}
                        >
                            {`${item.user.firstName} ${item.user.lastName}`}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Chip
                    size="small"
                    label={
                        <Typography
                            textTransform="capitalize"
                            sx={{
                                color: '#858585',
                                fontWeight: '500',
                                fontSize: '12px',
                                lineHeight: '19.9px',
                                letterSpacing: '0.4px'
                            }}
                        >
                            {item?.action && <FormattedMessage id={item?.action} />}
                        </Typography>
                    }
                    sx={{
                        border: `1px solid #858585`,
                        backgroundColor: 'transparent'
                    }}
                />
            </Box>
        </Box>
    );
};
