import { gql } from '@apollo/client';

export const QUERY_GET_RECORD_TYPES = gql`
    query GetRecordTypes {
        getRecordTypes {
            id
            name
            description
            tenantTypes {
                id
                name
                description
            }
            updatedAt
            createdAt
            enabled
        }
    }
`;

export const QUERY_FIND_RECORD_TYPES = gql`
    query FindRecordType($data: RecordTypeFindInput!) {
        findRecordType(data: $data) {
            id
            name
            enabled
        }
    }
`;
