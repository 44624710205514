import { SvgIcon, SvgIconProps } from '@mui/material';

export const EyeOpen = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0013 6.33333C12.8725 6.33333 15.4331 7.75333 16.6831 10C15.4331 12.2467 12.8801 13.6667 10.0013 13.6667C7.12251 13.6667 4.56948 12.2467 3.31948 10C4.56948 7.75333 7.13009 6.33333 10.0013 6.33333ZM10.0013 5C6.21342 5 2.97857 7.07333 1.66797 10C2.97857 12.9267 6.21342 15 10.0013 15C13.7892 15 17.024 12.9267 18.3346 10C17.024 7.07333 13.7892 5 10.0013 5ZM10.0013 8.33333C11.0468 8.33333 11.8952 9.08 11.8952 10C11.8952 10.92 11.0468 11.6667 10.0013 11.6667C8.95585 11.6667 8.10736 10.92 8.10736 10C8.10736 9.08 8.95585 8.33333 10.0013 8.33333ZM10.0013 7C8.12251 7 6.59221 8.34667 6.59221 10C6.59221 11.6533 8.12251 13 10.0013 13C11.8801 13 13.4104 11.6533 13.4104 10C13.4104 8.34667 11.8801 7 10.0013 7Z"
                fill="#37326E"
            />
        </svg>
    </SvgIcon>
);
