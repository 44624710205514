import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, ExpandMore } from '@mui/icons-material';
import { Box, Checkbox, FormGroup, Grid, Link, MenuItem, Popover, Tooltip, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import React, { MouseEvent, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'store';
import { IRecordField, IRecordFieldsList } from 'ui-component/records/types';
import { getTimeValidator } from 'ui-component/records/utils';
import { getDateWithoutTZ, getRelativeDateTimeUTCFormat } from 'ui-component/records/utils/dateHelpers';
import { ListValue } from 'views/backoffice/CustomLists/types';

export type RenderViewFieldProps = {
    field: FieldToView;
    handleOpenViewObjectPanel: (field: IRecordField, fieldName: string, editable: boolean) => void;
    headerData: IRecordFieldsList | null;
};

export type FieldToView = {
    label: string;
    dataType: IRecordField['dataType'];
    value: any;
    isBaseField?: boolean;
    options?: any[];
};

export const RenderViewField = ({ field, handleOpenViewObjectPanel, headerData }: RenderViewFieldProps) => {
    const { drawerOpen } = useSelector((state) => state.menu);
    const theme = useTheme();
    const { label, dataType, value, options = [] } = field;
    const isListOfObjects = headerData?.[label as keyof IRecordFieldsList].listType?.objectDefinition?.id;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const multipleSelectPanelOpen = Boolean(anchorEl);

    const handleToggleMultipleSelectPanel = (event: MouseEvent<HTMLElement>) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    let formattedFieldValue = <></>;
    if (dataType.toLowerCase() === 'date' && value.length)
        formattedFieldValue = (
            <Grid item xs={6}>
                <Tooltip
                    placement="bottom-start"
                    title={field.isBaseField ? getRelativeDateTimeUTCFormat(value) : new Date(value).toDateString()}
                >
                    <Typography
                        style={{
                            maxWidth: '90%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            paddingLeft: 10,
                            padding: 10,
                            fontWeight: 400,
                            fontSize: '13px'
                        }}
                        color="#818181"
                    >
                        {format(getDateWithoutTZ(value as string), 'MM/dd/yyyy')}
                    </Typography>
                </Tooltip>
            </Grid>
        );
    if (dataType.toLowerCase() === 'time' && value.length)
        formattedFieldValue = (
            <Grid item xs={6}>
                <Typography
                    style={{
                        maxWidth: '90%',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingLeft: 10,
                        padding: 10,
                        fontWeight: 400,
                        fontSize: '13px'
                    }}
                    color="#818181"
                >
                    {getTimeValidator(value as string)}
                </Typography>
            </Grid>
        );

    if (dataType.toLowerCase() === 'datetime' && value.length)
        formattedFieldValue = (
            <Grid item xs={6}>
                <Tooltip
                    placement="bottom-start"
                    // @ts-ignore
                    title={value.length ? new Date(value).toGMTString().replace('GMT', 'UTC') : ''}
                >
                    <Typography
                        style={{
                            maxWidth: '90%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            paddingLeft: 10,
                            padding: 10,
                            fontWeight: 400,
                            fontSize: '13px'
                        }}
                        color="#818181"
                    >
                        {value.length ? format(new Date(value as string), 'MM/dd/yyyy hh:mm a') : ''}
                    </Typography>
                </Tooltip>
            </Grid>
        );

    if (
        (dataType.toLowerCase() === 'object' || (dataType.toLowerCase() === 'dropdown' && isListOfObjects)) &&
        headerData?.[label as keyof IRecordFieldsList]
    ) {
        formattedFieldValue = (
            <>
                <Grid item xs={12} style={{ padding: '5px 0px' }}>
                    <Grid container sx={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                        <Grid item xs={drawerOpen ? 12 : 6} sx={{ paddingLeft: !drawerOpen ? 'unset' : '10px' }}>
                            {!!value && (
                                <Typography
                                    style={{
                                        paddingLeft: 10,
                                        padding: 10,
                                        fontWeight: 400,
                                        fontSize: '13px',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        color: theme.palette.secondary.main
                                    }}
                                    color="#818181"
                                    onClick={() => handleOpenViewObjectPanel(headerData[label as keyof IRecordFieldsList], label, false)}
                                >
                                    {value}
                                </Typography>
                            )}
                            {!value && (
                                <Typography
                                    style={{
                                        paddingLeft: 10,
                                        padding: 10,
                                        fontWeight: 400,
                                        fontSize: '13px',
                                        textDecoration: 'underline',
                                        color: theme.palette.secondary.main
                                    }}
                                    color="#818181"
                                >
                                    No Data
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }

    if (['string', 'number', 'decimal', 'currency', 'textarea', 'dropdown'].includes(dataType.toLowerCase()) && !isListOfObjects)
        formattedFieldValue = (
            <Grid item xs={6}>
                <Typography
                    style={{
                        maxWidth: '90%',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingLeft: 10,
                        padding: 10,
                        fontWeight: 400,
                        fontSize: '13px'
                    }}
                    color="#818181"
                >
                    {value}
                </Typography>
            </Grid>
        );

    if (dataType.toLowerCase() === 'checkbox')
        formattedFieldValue = (
            <Grid xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid xs={6}>
                    <Checkbox
                        defaultChecked
                        checked={value === 'true'}
                        disabled
                        sx={{
                            padding: '10px',
                            paddingLeft: '0px',
                            color: theme.palette.primary[400],
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        }}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                </Grid>
            </Grid>
        );

    if (dataType.toLowerCase() === 'url')
        formattedFieldValue = (
            <Grid item xs={6}>
                <Link
                    style={{ textDecoration: 'none' }}
                    href={value.includes('http') || value.includes('https') ? value : `https://${value}`}
                    target="_blank"
                >
                    <Typography
                        style={{
                            maxWidth: '90%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            paddingLeft: 10,
                            padding: 10,
                            fontWeight: 400,
                            fontSize: '13px',
                            textDecoration: 'underline'
                        }}
                        color="#818181"
                    >
                        {value}
                    </Typography>
                </Link>
            </Grid>
        );

    if (dataType.toLowerCase() === 'multiselect-checkbox')
        formattedFieldValue = (
            <Grid item xs={12}>
                {options
                    .filter((el) => el.enabled)
                    .map((item: ListValue) => (
                        <FormGroup style={{ padding: 10 }} key={item.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={6} sx={{ paddingLeft: '30px' }}>
                                    <Typography style={{ fontWeight: 400, fontSize: '13px' }}>
                                        <FormattedMessage
                                            id={item.userValue?.name || item.value}
                                            defaultMessage={item.userValue?.name || item.value}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Checkbox
                                        checked={Array.isArray(value) && value.includes(Number(item.id))}
                                        disabled
                                        sx={{
                                            padding: 0,
                                            color: theme.palette.primary[400],
                                            '&.Mui-checked': { color: theme.palette.primary.dark },
                                            '& .MuiSvgIcon-root': { fontSize: 30 }
                                        }}
                                        icon={<CheckBoxOutlineBlankOutlined />}
                                        checkedIcon={<CheckBoxOutlined />}
                                    />
                                </Grid>
                            </Grid>
                        </FormGroup>
                    ))}
            </Grid>
        );
    if (dataType.toLowerCase() === 'multiselect')
        formattedFieldValue = (
            <Grid item xs={6}>
                {Array.isArray(value) && (
                    <Box ref={containerRef} onClick={handleToggleMultipleSelectPanel}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography
                                style={{
                                    maxWidth: '90%',
                                    overflowX: 'hidden',
                                    textOverflow: 'ellipsis',
                                    paddingLeft: 10,
                                    padding: 10,
                                    fontWeight: 400,
                                    fontSize: '13px'
                                }}
                                color="#818181"
                            >
                                {value.join(', ')}
                            </Typography>
                            {value.length > 0 && <ExpandMore htmlColor="#54595E" sx={{ cursor: 'pointer' }} />}
                        </Box>
                        {multipleSelectPanelOpen && (
                            <Popover
                                anchorEl={anchorEl}
                                open={multipleSelectPanelOpen}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                PaperProps={{
                                    sx: { borderRadius: '8px', ml: '0px', mt: '5px', width: containerRef.current?.clientWidth || '200px' }
                                }}
                            >
                                {value.length > 0 &&
                                    value.map((name, idx) => (
                                        <Tooltip title={name} key={`${name}-${idx}`}>
                                            <Box>
                                                <MenuItem sx={{ height: '40px', px: '12px', bgcolor: '#FFF' }}>
                                                    <Typography
                                                        fontSize="14px"
                                                        sx={{ overflow: 'hidden', width: '100%', textOverflow: 'ellipsis' }}
                                                    >
                                                        {name}
                                                    </Typography>
                                                </MenuItem>
                                            </Box>
                                        </Tooltip>
                                    ))}
                            </Popover>
                        )}
                    </Box>
                )}
            </Grid>
        );

    return (
        <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 10 }}>
            <Grid container sx={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                <Grid item xs={6} paddingLeft="20px">
                    <Typography style={{ fontWeight: 600, fontSize: '13px' }}>
                        <FormattedMessage id={label} defaultMessage={label} />
                    </Typography>
                </Grid>
                {!value && !['multiselect-checkbox', 'checkbox', 'object'].includes(dataType) ? (
                    <Grid item xs={12}>
                        <Box style={{ paddingLeft: 10, padding: 10, fontWeight: 400, fontSize: '13px' }} color="#818181" />
                    </Grid>
                ) : (
                    formattedFieldValue
                )}
            </Grid>
        </Grid>
    );
};
