import { HighlightOff, History } from '@mui/icons-material';
import { IconButton, List, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { LineItemIcon } from 'views/DocumentViewer/components/Icons';
import { IconMessageCircle2, IconPaperclip } from '@tabler/icons';
import { LogReportMode, PanelTitle } from '../utils';
import useIsAllowedByRecordType from 'hooks/useIsAllowedByRecordType';
import { useTheme } from '@mui/styles';
import FooterOptions from '../Footer';
import { ITenant } from 'types/tenant';

export interface HeaderPanelProps {
    toggleEditForm: () => void;
    toggleLogReport: () => void;
    changePanel: PanelTitle;
    logReportMode: LogReportMode;
    isCreate?: boolean;
    onToggleLineItems?: () => void;
    isObjectPanel?: boolean;
    onGoBack?: () => void;
    onToggleComments?: () => void;
    onToggleAttachments?: () => void;
    recordTypeId?: number;
    isAttachmentFieldAllowed: boolean;
    handleClickSave: () => Promise<void>;
    handleClickCancel: () => void;
    loading: boolean;
    isRecordView?: boolean;
    businessEntity?: Pick<ITenant, 'id' | 'name'> | null | undefined;
    recordDataId?: number;
}

const SideBar = ({
    toggleEditForm,
    changePanel,
    logReportMode,
    toggleLogReport,
    isCreate,
    onToggleLineItems,
    onGoBack,
    isObjectPanel = false,
    onToggleComments,
    onToggleAttachments,
    recordTypeId,
    isAttachmentFieldAllowed,
    handleClickSave,
    handleClickCancel,
    loading,
    isRecordView,
    businessEntity,
    recordDataId
}: HeaderPanelProps) => {
    const theme = useTheme();

    const { isAllowed: isAllowedToAccessLogReport } = useIsAllowedByRecordType('log report access', recordTypeId);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
                backgroundColor: '#F5F6F7',
                height: '100%',
                padding: 0.5
            }}
            data-testId="header-panel"
        >
            <Box gap={2} width="95%">
                {isCreate && (
                    <Box>
                        <List>
                            <Typography
                                variant={isObjectPanel ? 'h4' : 'h3'}
                                component="div"
                                sx={{
                                    paddingLeft: '10px',
                                    color: theme.palette.secondary.main,
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    letterSpacing: '0.15px',
                                    lineHeight: '24px'
                                }}
                            >
                                Details
                            </Typography>
                        </List>
                        {isObjectPanel && (
                            <Box>
                                <ToggleButtonGroup
                                    sx={{
                                        display: { xs: 'flex', md: 'flex' },
                                        justifyContent: 'flex-end',
                                        '& button': { height: '100%' }
                                    }}
                                >
                                    <Tooltip title="Close Object Panel">
                                        <IconButton
                                            onClick={() => onGoBack?.()}
                                            value="Close Object Panel"
                                            size="medium"
                                            sx={{
                                                color: theme.palette.primary[400],
                                                backgroundColor: '#FFF',
                                                fontWeight: 600,
                                                '&:hover': {
                                                    color: theme.palette.primary[400],
                                                    backgroundColor: theme.palette.primary[300]
                                                }
                                            }}
                                        >
                                            <HighlightOff />
                                        </IconButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                            </Box>
                        )}
                    </Box>
                )}

                {!isCreate && isAllowedToAccessLogReport && (
                    <>
                        {changePanel !== PanelTitle.LogReport && (
                            <Box>
                                <Tooltip title="Log report">
                                    <IconButton
                                        onClick={toggleLogReport}
                                        value="Log report"
                                        size="medium"
                                        sx={{
                                            color: theme.palette.primary[400],
                                            backgroundColor: '#FFF',
                                            fontWeight: 600,
                                            '&:hover': {
                                                color: theme.palette.primary[400],
                                                backgroundColor: theme.palette.primary[300]
                                            }
                                        }}
                                    >
                                        <History fontSize="medium" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}

                        {isObjectPanel && (
                            <Tooltip title="Close Object Panel">
                                <IconButton
                                    onClick={() => onGoBack?.()}
                                    value="Close Object Panel"
                                    size="medium"
                                    sx={{
                                        color: theme.palette.primary[400],
                                        backgroundColor: '#FFF',
                                        fontWeight: 600,
                                        '&:hover': {
                                            color: theme.palette.primary[400],
                                            backgroundColor: theme.palette.primary[300]
                                        }
                                    }}
                                >
                                    <HighlightOff />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )}

                {onToggleComments && !isCreate && changePanel !== PanelTitle.LogReport && (
                    <Box>
                        <Tooltip title="Comments">
                            <IconButton
                                onClick={onToggleComments}
                                size="medium"
                                sx={{
                                    color: theme.palette.primary[400],
                                    bgcolor:
                                        logReportMode === LogReportMode.Comments
                                            ? theme.palette.secondary.light
                                            : `${theme.palette.primary[300]} !important`,
                                    fontWeight: 600,
                                    height: '100%',
                                    '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                }}
                            >
                                <IconMessageCircle2
                                    color={
                                        logReportMode === LogReportMode.Comments ? theme.palette.secondary.main : theme.palette.primary[400]
                                    }
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}

                {onToggleAttachments && !isCreate && changePanel !== PanelTitle.LogReport && isAttachmentFieldAllowed && (
                    <Box>
                        <Tooltip title="Attachments">
                            <IconButton
                                onClick={onToggleAttachments}
                                size="medium"
                                sx={{
                                    color: theme.palette.primary[400],
                                    bgcolor:
                                        logReportMode === LogReportMode.Attachments
                                            ? theme.palette.secondary.light
                                            : `${theme.palette.primary[300]} !important`,
                                    fontWeight: 600,
                                    height: '100%',
                                    '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                }}
                            >
                                <IconPaperclip
                                    color={
                                        logReportMode === LogReportMode.Attachments
                                            ? theme.palette.secondary.main
                                            : theme.palette.primary[400]
                                    }
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}

                {!!onToggleLineItems && changePanel !== PanelTitle.LogReport && (
                    <Box>
                        <Tooltip title="Line Items">
                            <IconButton
                                onClick={onToggleLineItems}
                                value="line items"
                                size="medium"
                                sx={{
                                    color: theme.palette.primary[400],
                                    bgcolor:
                                        logReportMode === LogReportMode.LineItems
                                            ? theme.palette.secondary.light
                                            : `${theme.palette.primary[300]} !important`,
                                    fontWeight: 600,
                                    height: '100%',
                                    '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                }}
                            >
                                <LineItemIcon
                                    color={
                                        logReportMode === LogReportMode.LineItems
                                            ? theme.palette.secondary.main
                                            : theme.palette.primary[400]
                                    }
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </Box>

            {!(changePanel === PanelTitle.ViewObject || changePanel === PanelTitle.EditObject || changePanel === PanelTitle.LogReport) && (
                <>
                    <FooterOptions
                        panelState={changePanel}
                        onEdit={toggleEditForm}
                        onSave={handleClickSave}
                        onCancel={handleClickCancel}
                        isCreate={isCreate}
                        disabledButtons={loading}
                        isRecordView={isRecordView}
                        businessEntity={businessEntity}
                        recordTypeId={recordTypeId}
                        recordDataId={recordDataId}
                    />
                </>
            )}
        </Box>
    );
};

export default SideBar;
