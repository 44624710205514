import { GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-pro';
import { FilterDateCriteriaEnum, FilterNumberCriteriaEnum, FilterObjectCriteriaEnum, IRecordHeaders } from '../../types';
import { DateTimeEditCell, ListDropdown, ProblemCodeDropdown, StatusRecordDropdown, UsersDropdown } from '../../dropdowns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { useState } from 'react';
import { Dayjs } from 'dayjs';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import NumberFormat from 'react-number-format';
import { TargetTenantDropdown } from 'ui-component/records/dropdowns/TargetTenantDropdown';
import { getFormattedValue } from 'ui-component/RecordView/utils';
import { ListDataTypeEnum, ListObject } from 'views/backoffice/CustomLists/types';
import { EntitiesAssociatedDropdown } from 'ui-component/records/dropdowns/EntitiesAssociatedDropdown';

const UsersInputValue = (props: GridFilterInputValueProps & { multiple?: boolean }) => {
    const { item, applyValue, multiple } = props;

    const handleChange = (newValue: string | string[] | null) => {
        applyValue({ ...item, value: newValue });
    };

    return (
        <UsersDropdown
            label="Value"
            onChange={handleChange}
            initialValue={item.value || ''}
            variant="standard"
            size="small"
            native={!multiple}
            multiple={multiple}
            fromFilterPanel
        />
    );
};

const BusinessEntityInputValue = (props: GridFilterInputValueProps & { recordTypeId?: number; multiple?: boolean; native?: boolean }) => {
    const { item, applyValue, recordTypeId, multiple, native } = props;

    const handleChange = (newValue: string | string[] | null) => {
        applyValue({ ...item, value: newValue });
    };

    return (
        <EntitiesAssociatedDropdown
            recordTypeId={Number(recordTypeId)}
            label="Value"
            onChange={handleChange}
            initialValue={item.value || ''}
            multiple={multiple}
            native={native}
            fromFilterPanel
        />
    );
};

const StatusInputValue = (props: GridFilterInputValueProps & { recordTypeId?: number; multiple?: boolean }) => {
    const { item, applyValue, recordTypeId, multiple } = props;

    const handleChange = (newValue: string | string[] | null) => {
        applyValue({ ...item, value: newValue });
    };

    return (
        <StatusRecordDropdown
            label="Value"
            onChange={handleChange}
            recordTypeId={recordTypeId as number}
            initialValue={item.value || ''}
            variant="standard"
            size="small"
            native={!multiple}
            multiple={multiple}
            fromFilterPanel
        />
    );
};

const ProblemCodeInputValue = (props: GridFilterInputValueProps & { recordTypeId?: number; multiple?: boolean }) => {
    const { item, applyValue, recordTypeId, multiple } = props;

    const handleChange = (newValue: string | string[] | null) => {
        applyValue({ ...item, value: newValue });
    };

    return (
        <ProblemCodeDropdown
            label="Value"
            onChange={handleChange}
            recordTypeId={recordTypeId as number}
            initialValue={item.value || ''}
            variant="standard"
            size="small"
            native={!multiple}
            multiple={multiple}
            fromFilterPanel
        />
    );
};

const TargetTenantInputValue = (props: GridFilterInputValueProps & { recordTypeId?: number; multiple?: boolean }) => {
    const { item, applyValue, recordTypeId, multiple } = props;

    const handleChange = (newValue: string | string[] | null) => {
        applyValue({ ...item, value: newValue });
    };

    return (
        <TargetTenantDropdown
            label="Value"
            onChange={handleChange}
            recordTypeId={recordTypeId as number}
            initialValue={item.value || ''}
            variant="standard"
            size="small"
            native={!multiple}
            multiple={multiple}
            fromFilterPanel
        />
    );
};

const ListInputValue = (props: GridFilterInputValueProps & { listId?: number; multiple?: boolean; listType?: ListDataTypeEnum }) => {
    const { item, applyValue, listId, multiple, listType } = props;

    const handleChange = (newValue: string | string[] | null) => {
        applyValue({ ...item, value: newValue });
    };

    return (
        <ListDropdown
            label="Value"
            onChange={handleChange}
            listId={listId as number}
            initialValue={item.value || ''}
            variant="standard"
            size="small"
            native={!multiple}
            multiple={multiple}
            isListOfObjects={listType === 'Object'}
            fromFilterPanel
        />
    );
};

const DateInputValue = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;
    const [dateInput, setDateInput] = useState<Dayjs | null>(item.value);

    const handleChange = (newValue: Dayjs | null) => {
        if (!newValue || !newValue.isValid()) return;

        setDateInput(newValue);
        applyValue({ ...item, value: newValue.toISOString() });
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                label="Value"
                inputFormat="MM/DD/YYYY"
                value={dateInput}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField {...params} variant="standard" sx={{ pt: '3px !important', '& button': { mr: 0 } }} size="small" />
                )}
            />
        </LocalizationProvider>
    );
};

const DateTimeInputValue = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;
    const [dateInput, setDateInput] = useState<Date | string | null>(item.value);

    const handleChange = (newValue: Date | string | null) => {
        if (!newValue || !Date.parse(newValue as string)) return;
        const formattedValue = getFormattedValue(newValue, { dataType: 'datetime', id: item.id as string });
        setDateInput(newValue);
        applyValue({ ...item, value: formattedValue });
    };
    return <DateTimeEditCell field="Value" onChange={handleChange} value={dateInput} standard />;
};

const TimeInputValue = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;
    const [dateInput, setDateInput] = useState<Dayjs | null>(item.value);

    const handleChange = (newValue: Dayjs | null) => {
        if (!newValue || !newValue.isValid()) return;

        setDateInput(newValue);
        applyValue({ ...item, value: `01/01/2000 ${newValue.toDate().toTimeString().slice(0, 5)}` });
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                label="Value"
                inputFormat="HH:mm"
                value={dateInput}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField {...params} variant="standard" sx={{ pt: '3px !important', '& button': { mr: 0 } }} size="small" />
                )}
            />
        </LocalizationProvider>
    );
};

const CurrencyInputValue = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;
    const [currencyInput, setCurrencyInput] = useState(item.value);

    const handleChange = (newValue: string | null) => {
        if (!newValue) return;

        setCurrencyInput(newValue);
        applyValue({ ...item, value: newValue });
    };
    return (
        <NumberFormat
            customInput={TextField}
            value={currencyInput}
            onChange={(e: any) => {
                handleChange(e.target.value);
            }}
            thousandSeparator=","
            decimalScale={2}
            variant="filled"
            size="small"
        />
    );
};

const usersOperators: GridFilterOperator[] = [
    {
        label: FilterObjectCriteriaEnum.is,
        value: FilterObjectCriteriaEnum.is,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: UsersInputValue
    },
    {
        label: FilterObjectCriteriaEnum.isAnyOf,
        value: FilterObjectCriteriaEnum.isAnyOf,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: UsersInputValue,
        InputComponentProps: { multiple: true }
    },
    {
        label: FilterObjectCriteriaEnum.isNot,
        value: FilterObjectCriteriaEnum.isNot,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: UsersInputValue
    }
];

const businessEntityOperators: (recordTypeId: number) => GridFilterOperator[] = (recordTypeId: number) => [
    {
        label: FilterObjectCriteriaEnum.is,
        value: FilterObjectCriteriaEnum.is,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => {
                const fieldValue = Number(params.row[filterItem.field]?.id);
                const filterValue = Number(filterItem.value);
                switch (filterItem.operator) {
                    case FilterObjectCriteriaEnum.is:
                        return fieldValue === filterValue;
                    case FilterObjectCriteriaEnum.isAnyOf:
                        return (filterItem.value as string[]).includes(String(fieldValue));
                    case FilterObjectCriteriaEnum.isNot:
                        return fieldValue !== filterValue;
                    default:
                        return false;
                }
            };
        },
        InputComponent: BusinessEntityInputValue,
        InputComponentProps: { recordTypeId, native: true }
    },
    {
        label: FilterObjectCriteriaEnum.isAnyOf,
        value: FilterObjectCriteriaEnum.isAnyOf,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => {
                const fieldValue = Number(params.row[filterItem.field]?.id);
                const filterValue = Number(filterItem.value);
                switch (filterItem.operator) {
                    case FilterObjectCriteriaEnum.is:
                        return fieldValue === filterValue;
                    case FilterObjectCriteriaEnum.isAnyOf:
                        return (filterItem.value as string[]).includes(String(fieldValue));
                    case FilterObjectCriteriaEnum.isNot:
                        return fieldValue !== filterValue;
                    default:
                        return false;
                }
            };
        },
        InputComponent: BusinessEntityInputValue,
        InputComponentProps: { recordTypeId, multiple: true }
    },
    {
        label: FilterObjectCriteriaEnum.isNot,
        value: FilterObjectCriteriaEnum.isNot,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => {
                const fieldValue = Number(params.row[filterItem.field]?.id);
                const filterValue = Number(filterItem.value);
                switch (filterItem.operator) {
                    case FilterObjectCriteriaEnum.is:
                        return fieldValue === filterValue;
                    case FilterObjectCriteriaEnum.isAnyOf:
                        return (filterItem.value as string[]).includes(String(fieldValue));
                    case FilterObjectCriteriaEnum.isNot:
                        return fieldValue !== filterValue;
                    default:
                        return false;
                }
            };
        },
        InputComponent: BusinessEntityInputValue,
        InputComponentProps: { recordTypeId, native: true }
    }
];

const statusOperators: (recordTypeId: number) => GridFilterOperator[] = (recordTypeId: number) => [
    {
        label: FilterObjectCriteriaEnum.is,
        value: FilterObjectCriteriaEnum.is,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: StatusInputValue,
        InputComponentProps: { recordTypeId }
    },
    {
        label: FilterObjectCriteriaEnum.isAnyOf,
        value: FilterObjectCriteriaEnum.isAnyOf,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: StatusInputValue,
        InputComponentProps: { recordTypeId, multiple: true }
    },
    {
        label: FilterObjectCriteriaEnum.isNot,
        value: FilterObjectCriteriaEnum.isNot,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: StatusInputValue,
        InputComponentProps: { recordTypeId }
    }
];

const problemCodeOperators: (recordTypeId: number) => GridFilterOperator[] = (recordTypeId: number) => [
    {
        label: FilterObjectCriteriaEnum.is,
        value: FilterObjectCriteriaEnum.is,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: ProblemCodeInputValue,
        InputComponentProps: { recordTypeId }
    },
    {
        label: FilterObjectCriteriaEnum.isAnyOf,
        value: FilterObjectCriteriaEnum.isAnyOf,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: ProblemCodeInputValue,
        InputComponentProps: { recordTypeId, multiple: true }
    },
    {
        label: FilterObjectCriteriaEnum.isNot,
        value: FilterObjectCriteriaEnum.isNot,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: ProblemCodeInputValue,
        InputComponentProps: { recordTypeId }
    }
];

const targetTenantOperators: (recordTypeId: number) => GridFilterOperator[] = (recordTypeId: number) => [
    {
        label: FilterObjectCriteriaEnum.is,
        value: FilterObjectCriteriaEnum.is,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: TargetTenantInputValue,
        InputComponentProps: { recordTypeId }
    },
    {
        label: FilterObjectCriteriaEnum.isAnyOf,
        value: FilterObjectCriteriaEnum.isAnyOf,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: TargetTenantInputValue,
        InputComponentProps: { recordTypeId, multiple: true }
    },
    {
        label: FilterObjectCriteriaEnum.isNot,
        value: FilterObjectCriteriaEnum.isNot,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: TargetTenantInputValue,
        InputComponentProps: { recordTypeId }
    }
];

const listOperators: (listId: number, listType: ListDataTypeEnum) => GridFilterOperator[] = (listId, listType) => [
    {
        label: FilterObjectCriteriaEnum.is,
        value: FilterObjectCriteriaEnum.is,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: ListInputValue,
        InputComponentProps: { listId, listType }
    },
    {
        label: FilterObjectCriteriaEnum.isAnyOf,
        value: FilterObjectCriteriaEnum.isAnyOf,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: ListInputValue,
        InputComponentProps: { listId, multiple: true, listType }
    },
    {
        label: FilterObjectCriteriaEnum.isNot,
        value: FilterObjectCriteriaEnum.isNot,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: ListInputValue,
        InputComponentProps: { listId, listType }
    }
];

const multiListOperators: (listId: number) => GridFilterOperator[] = (listId: number) => [
    {
        label: FilterObjectCriteriaEnum.has,
        value: FilterObjectCriteriaEnum.is,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: ListInputValue,
        InputComponentProps: { listId }
    },
    {
        label: FilterObjectCriteriaEnum.hasAnyOf,
        value: FilterObjectCriteriaEnum.isAnyOf,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: ListInputValue,
        InputComponentProps: { listId, multiple: true }
    },
    {
        label: FilterObjectCriteriaEnum.hasNot,
        value: FilterObjectCriteriaEnum.isNot,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.row[filterItem.field]?.id) === Number(filterItem.value);
        },
        InputComponent: ListInputValue,
        InputComponentProps: { listId }
    }
];

const dateOperators: () => GridFilterOperator[] = () => [
    {
        label: FilterDateCriteriaEnum.is,
        value: FilterDateCriteriaEnum.is,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: DateInputValue
    },
    {
        label: FilterDateCriteriaEnum.isBefore,
        value: FilterDateCriteriaEnum.isBefore,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: DateInputValue
    },
    {
        label: FilterDateCriteriaEnum.isAfter,
        value: FilterDateCriteriaEnum.isAfter,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: DateInputValue
    },
    {
        label: FilterDateCriteriaEnum.isNot,
        value: FilterDateCriteriaEnum.isNot,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: DateInputValue
    },
    {
        label: FilterDateCriteriaEnum.isOnOrAfter,
        value: FilterDateCriteriaEnum.isOnOrAfter,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: DateInputValue
    },
    {
        label: FilterDateCriteriaEnum.isOnOrBefore,
        value: FilterDateCriteriaEnum.isOnOrBefore,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: DateInputValue
    }
];

const dateTimeOperators: () => GridFilterOperator[] = () => [
    {
        label: FilterDateCriteriaEnum.is,
        value: FilterDateCriteriaEnum.is,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: DateTimeInputValue
    },
    {
        label: FilterDateCriteriaEnum.isBefore,
        value: FilterDateCriteriaEnum.isBefore,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: DateTimeInputValue
    },
    {
        label: FilterDateCriteriaEnum.isAfter,
        value: FilterDateCriteriaEnum.isAfter,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: DateTimeInputValue
    },
    {
        label: FilterDateCriteriaEnum.isNot,
        value: FilterDateCriteriaEnum.isNot,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: DateTimeInputValue
    },
    {
        label: FilterDateCriteriaEnum.isOnOrAfter,
        value: FilterDateCriteriaEnum.isOnOrAfter,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: DateTimeInputValue
    },
    {
        label: FilterDateCriteriaEnum.isOnOrBefore,
        value: FilterDateCriteriaEnum.isOnOrBefore,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: DateTimeInputValue
    }
];

const timeOperators: () => GridFilterOperator[] = () => [
    {
        label: FilterDateCriteriaEnum.is,
        value: FilterDateCriteriaEnum.is,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: TimeInputValue
    },
    {
        label: FilterDateCriteriaEnum.isBefore,
        value: FilterDateCriteriaEnum.isBefore,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: TimeInputValue
    },
    {
        label: FilterDateCriteriaEnum.isAfter,
        value: FilterDateCriteriaEnum.isAfter,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: TimeInputValue
    },
    {
        label: FilterDateCriteriaEnum.isNot,
        value: FilterDateCriteriaEnum.isNot,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: TimeInputValue
    },
    {
        label: FilterDateCriteriaEnum.isOnOrAfter,
        value: FilterDateCriteriaEnum.isOnOrAfter,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: TimeInputValue
    },
    {
        label: FilterDateCriteriaEnum.isOnOrBefore,
        value: FilterDateCriteriaEnum.isOnOrBefore,
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.value || !filterItem.operator) {
                return null;
            }
            const parsedValue = new Date(filterItem.value as string);
            return (params): boolean => {
                const value = params.row[column.field] as Date;
                return value.getTime() === parsedValue.getTime();
            };
        },
        InputComponent: TimeInputValue
    }
];

export const currencyOperators: () => GridFilterOperator[] = () => [
    {
        label: FilterNumberCriteriaEnum.equals,
        value: FilterNumberCriteriaEnum.equals,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.value) === Number(filterItem.value);
        },
        InputComponent: CurrencyInputValue
    },

    {
        label: FilterNumberCriteriaEnum.greaterThan,
        value: FilterNumberCriteriaEnum.greaterThan,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.value) > Number(filterItem.value);
        },
        InputComponent: CurrencyInputValue
    },

    {
        label: FilterNumberCriteriaEnum.greaterThanOrEqual,
        value: FilterNumberCriteriaEnum.greaterThanOrEqual,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.value) >= Number(filterItem.value);
        },
        InputComponent: CurrencyInputValue
    },

    {
        label: FilterNumberCriteriaEnum.lessThan,
        value: FilterNumberCriteriaEnum.lessThan,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.value) < Number(filterItem.value);
        },
        InputComponent: CurrencyInputValue
    },

    {
        label: FilterNumberCriteriaEnum.lessThanOrEqual,
        value: FilterNumberCriteriaEnum.lessThanOrEqual,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.value) <= Number(filterItem.value);
        },
        InputComponent: CurrencyInputValue
    },

    {
        label: FilterNumberCriteriaEnum.notEqual,
        value: FilterNumberCriteriaEnum.notEqual,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.value) !== Number(filterItem.value);
        },
        InputComponent: CurrencyInputValue
    }
];

export const filterOperatorList: Partial<
    Record<keyof IRecordHeaders | 'customMultiList' | 'customList' | 'date' | 'dateTime' | 'time' | 'currency', any>
> = {
    statusId: (recordType: number) => statusOperators(recordType),
    problemCodeId: (recordType: number) => problemCodeOperators(recordType),
    targetTransmissionTenant: (recordType: number) => targetTenantOperators(recordType),
    businessEntity: (recordType: number) => businessEntityOperators(recordType),
    customList: (data: ListObject) => listOperators(+data.id, data.dataType),
    customMultiList: (listId: number) => multiListOperators(listId),
    approvedBy: () => usersOperators,
    assignedTo: () => usersOperators,
    receivedBy: () => usersOperators,
    createdBy: () => usersOperators,

    date: dateOperators,
    dateTime: dateTimeOperators,
    time: timeOperators,
    currency: currencyOperators
};
