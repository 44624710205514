import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_FILTER_AUDIT_LOGS_FOR_RECORDS } from 'graphql/queries/records';
import { useEffect, useState } from 'react';
import {
    AuditLog,
    CommentLog,
    GetFilterAuditLogsVariables,
    LineItemTypes,
    QueryGetFilterAuditLogs,
    QueryGetFilterCommentsLogs,
    QueryGetFilterLineItemsLogs
} from 'ui-component/RecordView/types';
import { LogReportMode } from '../../utils';
import { QUERY_GET_FILTER_AUDIT_LOGS_FOR_LINE_ITEMS } from 'graphql/queries/lineItems';
import { QUERY_GET_COMMENTS_LOGS } from 'graphql/queries/comments';
import { useDocumentViewer } from 'views/DocumentViewer/hooks/useDocumentViewer';

function useAuditLogs(logReportMode: LogReportMode, recordId: number, tenantId: number) {
    const { lineItem, comment } = useDocumentViewer();
    const [logs, setLogs] = useState<AuditLog[] | CommentLog[] | LineItemTypes[] | undefined>([]);
    const [total, setTotal] = useState(0);

    const [findAuditLogsRecords, { loading: loadingRecords, data: dataRecords, fetchMore: fetchMoreLogsRecords }] = useLazyQuery<
        QueryGetFilterAuditLogs,
        GetFilterAuditLogsVariables
    >(QUERY_GET_FILTER_AUDIT_LOGS_FOR_RECORDS, {
        fetchPolicy: 'network-only',
        variables: {
            data: { filterInput: { recordId, tenantId }, pagination: { limit: 25, offset: 0 } }
        }
    });

    const [findAuditLogsComments, { loading: loadingComments, data: dataComments, fetchMore: fetchMoreLogsComments }] = useLazyQuery<
        QueryGetFilterCommentsLogs,
        GetFilterAuditLogsVariables
    >(QUERY_GET_COMMENTS_LOGS, {
        fetchPolicy: 'network-only',
        variables: {
            data: { filterInput: { recordId, tenantId }, pagination: { limit: 25, offset: 0 } }
        }
    });

    const [findAuditLogLineItems, { loading: loadingLineItems, data: dataLineItems }] = useLazyQuery<
        QueryGetFilterLineItemsLogs,
        GetFilterAuditLogsVariables
    >(QUERY_GET_FILTER_AUDIT_LOGS_FOR_LINE_ITEMS, {
        fetchPolicy: 'network-only',
        variables: {
            data: { filterInput: { recordId, tenantId }, pagination: { limit: 25, offset: 0 } }
        }
    });

    useEffect(() => {
        if (recordId && logReportMode === LogReportMode.All) {
            findAuditLogsRecords();
        }

        if ((recordId && logReportMode === LogReportMode.Comments) || comment) {
            findAuditLogsComments();
        }

        if ((recordId && logReportMode === LogReportMode.LineItems) || lineItem) {
            findAuditLogLineItems();
        }
    }, [recordId, logReportMode, lineItem, comment, findAuditLogsRecords, findAuditLogsComments, findAuditLogLineItems]);

    useEffect(() => {
        if (logReportMode === LogReportMode.All || logReportMode === LogReportMode.Objects) {
            setLogs(dataRecords?.filterAuditLogsForRecord?.logs || []);
            setTotal(dataRecords?.filterAuditLogsForRecord?.total || 0);
        }

        if (logReportMode === LogReportMode.Comments) {
            setLogs(dataComments?.filterCommentLogsByRecordHeader?.logs || []);
            setTotal(dataComments?.filterCommentLogsByRecordHeader?.total || 0);
        }

        if (logReportMode === LogReportMode.LineItems) {
            const lineItemsLogs =
                dataLineItems?.filterAuditLogsForLineItems?.logs?.map((log) => ({
                    ...log,
                    action: log.rowAction
                })) || [];

            setLogs(lineItemsLogs);
            setTotal(dataLineItems?.filterAuditLogsForLineItems?.total || 0);
        }
    }, [
        dataRecords?.filterAuditLogsForRecord?.logs,
        dataComments?.filterCommentLogsByRecordHeader?.logs,
        dataLineItems?.filterAuditLogsForLineItems?.logs,
        dataRecords?.filterAuditLogsForRecord?.total,
        dataComments?.filterCommentLogsByRecordHeader?.total,
        dataLineItems?.filterAuditLogsForLineItems?.total,
        logReportMode
    ]);

    return {
        loading: loadingRecords || loadingComments || loadingLineItems,
        logs,
        total,
        fetchMoreLogsRecords,
        fetchMoreLogsComments
    };
}

export default useAuditLogs;
