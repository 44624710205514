import { GridColDef } from '@mui/x-data-grid-pro';
import { ILineItems, ILineItemsFields, LineItemLog, LineItemLogRow, LineItemsFields } from './types';
import { IRecordField, IRecordFieldsList } from '../types';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { getAuditFormattedValue } from 'ui-component/RecordView/utils';
import { AuditLog } from 'ui-component/RecordView/types';

/**
 *  Generate rows for Line item Grid
 *
 * @param lineItemFields {ILineItemsFields[]} Line item fields
 * @param lineItems {ILineItems[]} Line item values
 */
export const generateLineItemRows = (lineItemFields?: ILineItemsFields[], lineItems?: ILineItems[]) => {
    if (!lineItemFields) return [];
    const arr: any[] = [];

    if (lineItems) {
        const { lineItemWithoutRowId, lineItemsWithRowId } = splitLineItemsByRowId(lineItems);

        for (const item of lineItemsWithRowId) {
            const [, , fieldId] = item.tag.split(';');
            const headerObj = getFieldById(fieldId, lineItemFields);
            const rowId = item.lineItemsRow?.id as string;
            if (headerObj) {
                if (arr[+rowId]) arr[+rowId][`${headerObj.recordAdditionalFields.name}-${headerObj.order}`] = item.value;
                else
                    arr[+rowId] = {
                        [`${headerObj.recordAdditionalFields.name}-${headerObj.order}`]: item.value,
                        id: item.index,
                        idRowLog: rowId
                    };
            }
        }

        for (const item of lineItemWithoutRowId) {
            const [, , fieldId] = item.tag.split(';');
            const headerObj = getFieldById(fieldId, lineItemFields);

            if (headerObj) {
                if (arr[item.index - 1] && !arr[item.index - 1]?.autogenerate)
                    arr[item.index - 1][`${headerObj.recordAdditionalFields.name}-${headerObj.order}`] = item.value;
                else arr[item.index - 1] = { [`${headerObj.recordAdditionalFields.name}-${headerObj.order}`]: item.value, id: item.index };
            }
        }
    }

    return arr.filter((el) => el !== undefined || el !== null);
};

/**
 * Returns an object with lineItemWithoutRowId and lineItemsWithRowId arrays
 *
 * @param lineItems {ILineItems[]}
 */
export const splitLineItemsByRowId = (lineItems: ILineItems[]) =>
    lineItems.reduce<{
        lineItemsWithRowId: ILineItems[];
        lineItemWithoutRowId: ILineItems[];
    }>(
        (acc, el) => {
            if (el.lineItemsRow?.id) return { ...acc, lineItemsWithRowId: [...acc.lineItemsWithRowId, el] };
            return { ...acc, lineItemWithoutRowId: [...acc.lineItemWithoutRowId, el] };
        },
        { lineItemsWithRowId: [], lineItemWithoutRowId: [] }
    );

export const generateLineItemRow = (headersDef: GridColDef[]) => {
    const arr: any[] = [];

    const filledArr = fillLineItemArr(arr, headersDef);

    return filledArr;
};

/**
 *  Return the line item field ILineItemsFields for given id or undefined
 *
 * @param fieldId {string} field id of Line item
 * @param lineItemHeaders {ILineItemsFields[]} Array of fields of the lineItem configuration
 */
export const getFieldById = (fieldId: string, lineItemHeaders: ILineItemsFields[]) =>
    lineItemHeaders.find((el) => Number(el.recordAdditionalFields.id) === Number(fieldId));

/**
 * Fill the given array or empty spaces in the array with empty line item rows
 *
 * @param arr {(Object | undefined)[]} Array of line item rows generated
 * @param headersDef {GridColDef[]} Definitions for columns
 */
export const fillLineItemArr = (arr: (Record<string, string | number> | undefined)[], headersDef: GridColDef[]) => {
    if (!arr.length) return generateEmptyLineItemRows(headersDef);

    const limit = arr.length > 5 ? arr.length : 5;

    const filledArr: Record<string, any>[] = [];
    for (let idx = 0; idx < limit; idx += 1) {
        filledArr[idx] = arr[idx] || generateEmptyLineItemRows(headersDef, 1, idx)[0];
    }

    return filledArr;
};

/**
 * Generate empty Line Item rows based on columns
 *
 * @param headersDef {GridColDef[]} Grid definition for columns
 * @param quantity {Number} Quantity of empty line item rows to create
 * @param startId {Number} Starting Id for the new empty line item rows
 */
export const generateEmptyLineItemRows = (headersDef: GridColDef[], quantity: number = 5, startId: number = 0) =>
    [...Array(quantity).keys()].map((el) => ({
        id: startId + el,
        autogenerate: true,
        ...headersDef.reduce((acc, header) => ({ ...acc, [header.field]: '' }), {})
    }));

/**
 * Returns value from the stored line item data
 *
 * @param index {Object} The index for the line item, normally is the id of the newRow from the grid
 * @param lineItemsData {ILineItems{}} The raw data of the line items
 * @param headers {ILineItemsFields[]} Headers for line items
 * @param key {String} key
 */
export const getValueFromLineItemsData = (
    index: Record<string, any>,
    lineItemsData?: ILineItems[],
    headers?: ILineItemsFields[],
    key?: string
) => {
    if (!lineItemsData || !headers || !key) return undefined;

    const [fieldNameFromUpdated, fieldOrder] = key.split('-');

    return lineItemsData.find((el) => {
        const [, , fieldId] = el.tag.split(';');

        const idFromAditionalField = headers.find(
            (typeField) => typeField.recordAdditionalFields.name === fieldNameFromUpdated && typeField.order === Number(fieldOrder)
        )?.recordAdditionalFields.id;

        return Number(el.index) === Number(index) && Number(fieldId) === Number(idFromAditionalField);
    });
};

/**
 * Return the line item fields with the same shape as common fields
 *
 * @param lineItemHeaders {ILIneItemsFields}
 */
export const getFieldsAsHeadersFields = (lineItemHeaders?: ILineItemsFields[]) => {
    if (!lineItemHeaders || !lineItemHeaders.length) return {};
    return lineItemHeaders
        .map((el) => el.recordAdditionalFields)
        .reduce<Partial<IRecordFieldsList>>((acc, el) => ({ ...acc, [el.name]: el as unknown as IRecordField }), {});
};

/**
 * Fields that are not taken in consideration to save the line items
 */
const NON_SUBMITABLE_LINE_ITEMS_FIELDS = ['id', 'order'] as const;

/**
 * Filters the field dependinf in
 *
 * @param newOne {Object} Current data from the form
 * @param currentData {ILineItems[]}
 * @param headers {ILineItemsFields[]}
 */
export const getDirtyLineItemFields = (newOne: Record<string, any>, currentData?: ILineItems[], headers?: ILineItemsFields[]) => {
    let rowId: string | number = 0;
    const keys = Object.keys(newOne)
        .filter((key) => !NON_SUBMITABLE_LINE_ITEMS_FIELDS.includes(key as (typeof NON_SUBMITABLE_LINE_ITEMS_FIELDS)[number]))
        .filter((key) => {
            const newValue = newOne[key];
            const oldData = getValueFromLineItemsData(newOne.id, currentData, headers, key);

            // This is needed to get the rowId even in the non created items
            if (rowId === 0) rowId = oldData?.lineItemsRow?.id ?? 0;

            return !oldData || newValue !== oldData.value;
        });

    return { rowId, keys };
};

/**
 * Map the values to be used in request and filter out the unnecesary
 *
 * @param newData {Object} Current data from the form
 * @param keys {String[]}
 * @param currentData {ILineItems[]}
 * @param headers {ILineItemsFields[]}
 * @returns
 */
export const mapLineItemFieldsFromKeys = (
    newData: Record<string, any>,
    keys: string[],
    currentData?: ILineItems[],
    headers?: ILineItemsFields[]
): LineItemsFields[] =>
    keys
        .map((key) => {
            const [fieldNameFromUpdated, fieldOrder] = key.split('-');
            const idFromHeader = Number(
                headers?.find((el) => el.recordAdditionalFields.name === fieldNameFromUpdated && el.order === Number(fieldOrder))?.id
            );

            const oldData = getValueFromLineItemsData(newData.id, currentData, headers, key);

            // rowId = oldData?.lineItemsRow?.id || 0;

            const id = Number(oldData?.id);

            if (id && idFromHeader) {
                return {
                    id,
                    lineItemsByTypeFiledsId: idFromHeader,
                    value: newData[key]
                } as LineItemsFields;
            }

            if (newData[key] && idFromHeader) {
                return {
                    lineItemsByTypeFiledsId: idFromHeader,
                    value: newData[key]
                } as LineItemsFields;
            }

            return null;
        })
        .filter((el) => el !== null) as LineItemsFields[];

/**
 * returns the LineItemFields but with index and record header id
 *
 * @param items {LineItemFields[]}
 * @param id {String}
 * @param recordId {Number}
 */
export const getLineItemFieldsWithRecordData = (items: LineItemsFields[], id: string, recordId: number) =>
    items.map((item) =>
        item.id
            ? {
                  ...item,
                  id: item.id as number,
                  index: Number(id),
                  recordHeaderId: recordId
              }
            : {
                  ...item,
                  index: Number(id),
                  recordHeaderId: recordId
              }
    );

/**
 * Maps a list of line item logs to a table format suitable for audit logs.
 *
 * @param lineItemId - The ID of the line item to filter logs for.
 * @param lineItemLogs - An array of line item logs to be processed.
 * @returns An array of line item log rows formatted for the audit log table.
 *
 * The function performs the following steps:
 * 1. Filters the provided logs to include only those that match the given line item ID.
 * 2. Iterates over the filtered logs to create a formatted log row for each entry.
 * 3. For each log entry, it constructs a log row object containing:
 *    - `id`: The ID of the log entry.
 *    - `name`: An empty string (can be customized as needed).
 *    - `before`: A comma-separated string of old values from the log changes.
 *    - `after`: A comma-separated string of new values from the log changes.
 *    - `activity`: The action performed on the log entry.
 *    - `user`: The name of the user who performed the action.
 *    - `dateUpdated`: The date and time when the log entry was updated.
 *    - `objectLogs`: An array of detailed change logs for the log entry.
 * 4. Checks if the log row already exists in the result array based on the log ID and date.
 * 5. If the log row exists, it updates the `before` and `after` fields by appending new values.
 * 6. If the log row does not exist, it adds the new log row to the result array.
 * 7. Finally, it appends an index to the `id` of each log row to ensure uniqueness and returns the result.
 */
export const mapRecordLogToLineItemAuditLogTable = (lineItemId: number, lineItemLogs: LineItemLog[]): LineItemLogRow[] => {
    const logsRow: LineItemLogRow[] = [];

    const filteredLogs = lineItemLogs?.filter((log) => log?.row?.id === lineItemId);

    filteredLogs?.map((lineItemLog) => {
        const logRow: LineItemLogRow = {
            id: lineItemLog?.row?.id,
            name: '',
            before: lineItemLog.changes.reduce(
                // eslint-disable-next-line no-nested-ternary
                (acc, value) => (acc ? `${acc}, ${value.oldValue}` : value.oldValue ? getAuditFormattedValue(value.oldValue) : ''),
                ''
            ),
            after: lineItemLog.changes.reduce(
                // eslint-disable-next-line no-nested-ternary
                (acc, value) => (acc ? `${acc}, ${value.newValue}` : value.newValue ? getAuditFormattedValue(value.newValue) : ''),
                ''
            ),
            activity: lineItemLog.rowAction,
            user: lineItemLog.user.name ?? `${lineItemLog.user.firstName} ${lineItemLog.user.lastName}`,
            dateUpdated: lineItemLog.datetime,
            objectLogs: lineItemLog.changes.map((change) => ({
                id: uuidv4(),
                name: change.what,
                before: change.oldValue ? getAuditFormattedValue(change.oldValue) : '',
                after: change.newValue ? getAuditFormattedValue(change.newValue) : '',
                activity: change.action,
                user: '',
                dateUpdated: ''
            }))
        };

        if (logsRow.length === 0) {
            logsRow.push(logRow);

            return logRow;
        }

        const foundItems = logsRow.filter((logRowItem) => {
            const foundFormattedDate = format(new Date(logRowItem.dateUpdated), 'MM/dd/yyyy');
            const currentFormattedDate = format(new Date(lineItemLog.datetime), 'MM/dd/yyyy');

            return logRowItem?.id === lineItemLog?.row?.id && foundFormattedDate === currentFormattedDate;
        });

        if (foundItems.length === 0) {
            logsRow.push(logRow);

            return logRow;
        }

        const foundItem = foundItems?.[0];

        const before = lineItemLog.changes.reduce(
            // eslint-disable-next-line no-nested-ternary
            (acc, value) => (acc ? `${acc}, ${value.oldValue}` : value.oldValue ? getAuditFormattedValue(value.oldValue) : ''),
            ''
        );

        foundItem.before = foundItem.before ? `${foundItem.before}${before ? `, ${before}` : ''}` : before || '';

        const after = lineItemLog.changes.reduce(
            // eslint-disable-next-line no-nested-ternary
            (acc, value) => (acc ? `${acc}, ${value.newValue}` : value.newValue ? getAuditFormattedValue(value.newValue) : ''),
            ''
        );

        foundItem.after = foundItem.after ? `${foundItem.after}${after ? `, ${after}` : ''}` : after || '';

        foundItem.objectLogs = [
            ...(foundItem.objectLogs ?? []),
            ...lineItemLog.changes.map((change) => ({
                id: uuidv4(),
                name: change.what,
                before: change.oldValue ? getAuditFormattedValue(change.oldValue) : '',
                after: change.newValue ? getAuditFormattedValue(change.newValue) : '',
                activity: '',
                user: '',
                dateUpdated: ''
            }))
        ];

        return foundItem;
    });

    const logsRowDifferentIds = logsRow.map((item, i) => ({
        ...item,
        id: `${item.id} ${i}`
    }));

    return logsRowDifferentIds as unknown as LineItemLogRow[];
};

/**
 * Retrieves a unique set of log IDs from an array of LineItemLog objects.
 *
 * @param {LineItemLog[]} items - The array of LineItemLog objects from which to extract log IDs.
 * @returns {string[]} An array of unique log IDs.
 */
export const getLogsIds = (items: LineItemLog[]) => [...new Set(items?.map((item) => item?.row?.id))];

export const mapRecordLogToLineItemAuditLogTableByName = (
    lineItemName: string,
    lineItemLogs: LineItemLog[] | AuditLog[]
): LineItemLogRow[] => {
    const logsRow: LineItemLogRow[] = [];

    const filteredLogs = (lineItemLogs as LineItemLog[])?.filter((log) => log?.user?.name === lineItemName);

    filteredLogs?.map((lineItemLog) => {
        const logRow: LineItemLogRow = {
            id: lineItemLog?.user?.name,
            name: '',
            before: lineItemLog.changes.reduce(
                // eslint-disable-next-line no-nested-ternary
                (acc, value) => (acc ? `${acc}, ${value.oldValue}` : value.oldValue ? getAuditFormattedValue(value.oldValue) : ''),
                ''
            ),
            after: lineItemLog.changes.reduce(
                // eslint-disable-next-line no-nested-ternary
                (acc, value) => (acc ? `${acc}, ${value.newValue}` : value.newValue ? getAuditFormattedValue(value.newValue) : ''),
                ''
            ),
            activity: lineItemLog.action,
            user: lineItemLog.user.name ?? `${lineItemLog.user.firstName} ${lineItemLog.user.lastName}`,
            dateUpdated: lineItemLog.datetime,
            objectLogs: lineItemLog.changes.map((change) => ({
                id: uuidv4(),
                name: change.what,
                before: change.oldValue ? String(change.oldValue) : '',
                after: change.newValue ? String(change.newValue) : '',
                activity: change.action,
                user: '',
                dateUpdated: ''
            }))
        };

        logRow.objectLogs = [
            ...lineItemLog.changes.map((change) => ({
                id: uuidv4(),
                name: change.what,
                before: change.oldValue ? String(change.oldValue) : '',
                after: change.newValue ? String(change.newValue) : '',
                activity: '',
                user: '',
                dateUpdated: ''
            }))
        ];

        if (logsRow.length === 0) {
            logsRow.push(logRow);

            return logRow;
        }

        const foundItems = logsRow.filter((logRowItem) => {
            const foundFormattedDate = format(new Date(logRowItem.dateUpdated), 'MM/dd/yyyy');
            const currentFormattedDate = format(new Date(lineItemLog.datetime), 'MM/dd/yyyy');

            return logRowItem?.id === lineItemLog?.row?.id && foundFormattedDate === currentFormattedDate;
        });

        if (foundItems.length === 0) {
            logsRow.push(logRow);

            return logRow;
        }

        const foundItem = foundItems?.[0];

        const before = lineItemLog.changes.reduce(
            // eslint-disable-next-line no-nested-ternary
            (acc, value) => (acc ? `${acc}, ${value.oldValue}` : value.oldValue ? String(value.oldValue) : ''),
            ''
        );

        foundItem.before = foundItem.before ? `${foundItem.before}${before ? `, ${before}` : ''}` : before || '';

        const after = lineItemLog.changes.reduce(
            // eslint-disable-next-line no-nested-ternary
            (acc, value) => (acc ? `${acc}, ${value.newValue}` : value.newValue ? String(value.newValue) : ''),
            ''
        );

        foundItem.after = foundItem.after ? `${foundItem.after}${after ? `, ${after}` : ''}` : after || '';

        foundItem.objectLogs = [
            ...(foundItem.objectLogs ?? []),
            ...lineItemLog.changes.map((change) => ({
                id: uuidv4(),
                name: change.what,
                before: change.oldValue ? String(change.oldValue) : '',
                after: change.newValue ? String(change.newValue) : '',
                activity: '',
                user: '',
                dateUpdated: ''
            }))
        ];

        return foundItem;
    });

    const logsRowDifferentIds = logsRow.map((item, i) => ({
        ...item,
        id: `${item.id}`
    }));

    return logsRowDifferentIds as unknown as LineItemLogRow[];
};

export const mapRecordLogToObjectsLogTableByName = (lineItemName: string, objectLogs: AuditLog[]): LineItemLogRow[] => {
    const logsRow: LineItemLogRow[] = [];

    const filteredLogs = objectLogs?.filter((log) => log?.user?.name === lineItemName);

    filteredLogs?.map((lineItemLog) => {
        const logRow: LineItemLogRow = {
            id: lineItemLog?.user?.name,
            name: '',
            before: lineItemLog.objectsLogs?.reduce((acc, detail) => {
                const oldValues = detail.changes?.reduce(
                    (innerAcc, change) =>
                        // eslint-disable-next-line no-nested-ternary
                        innerAcc ? `${innerAcc}, ${change.oldValue}` : change.oldValue ? getAuditFormattedValue(change.oldValue) : '',
                    ''
                );
                return acc ? `${acc}, ${oldValues}` : oldValues;
            }, ''),
            after: lineItemLog.objectsLogs?.reduce((acc, detail) => {
                const newValues = detail.changes?.reduce(
                    (innerAcc, change) =>
                        // eslint-disable-next-line no-nested-ternary
                        innerAcc ? `${innerAcc}, ${change.newValue}` : change.newValue ? getAuditFormattedValue(change.newValue) : '',
                    ''
                );
                return acc ? `${acc}, ${newValues}` : newValues;
            }, ''),
            activity: lineItemLog.action,
            user: lineItemLog.user?.name ?? `${lineItemLog.user?.firstName} ${lineItemLog.user?.lastName}`,
            dateUpdated: lineItemLog.datetime,
            objectLogs: []
        };

        logRow.objectLogs = [
            ...lineItemLog.objectsLogs.map((change) => ({
                id: uuidv4(),
                name: change.objectName,
                before: change.changes.reduce(
                    (acc, changeDetail) =>
                        // eslint-disable-next-line no-nested-ternary
                        acc ? `${acc}, ${changeDetail.oldValue}` : changeDetail.oldValue ? String(changeDetail.oldValue) : '',
                    ''
                ),
                after: change.changes.reduce(
                    (acc, changeDetail) =>
                        // eslint-disable-next-line no-nested-ternary
                        acc ? `${acc}, ${changeDetail.newValue}` : changeDetail.newValue ? String(changeDetail.newValue) : '',
                    ''
                ),
                activity: '',
                user: '',
                dateUpdated: ''
            }))
        ];

        if (logsRow.length === 0) {
            logsRow.push(logRow);

            return logRow;
        }

        const foundItems = logsRow.filter((logRowItem) => logRowItem?.id === lineItemLog?.row?.id);

        if (foundItems.length === 0) {
            logsRow.push(logRow);

            return logRow;
        }

        const foundItem = foundItems?.[0];

        const before = lineItemLog.objectsLogs?.reduce((acc, detail) => {
            const oldValues = detail.changes?.reduce(
                (innerAcc, change) =>
                    // eslint-disable-next-line no-nested-ternary
                    innerAcc ? `${innerAcc}, ${change.oldValue}` : change.oldValue ? getAuditFormattedValue(change.oldValue) : '',
                ''
            );
            return acc ? `${acc}, ${oldValues}` : oldValues;
        }, '');

        foundItem.before = foundItem.before ? `${foundItem.before}${before ? `, ${before}` : ''}` : before || '';

        const after = lineItemLog.objectsLogs?.reduce((acc, detail) => {
            const newValues = detail.changes?.reduce(
                (innerAcc, change) =>
                    // eslint-disable-next-line no-nested-ternary
                    innerAcc ? `${innerAcc}, ${change.newValue}` : change.newValue ? getAuditFormattedValue(change.newValue) : '',
                ''
            );
            return acc ? `${acc}, ${newValues}` : newValues;
        }, '');

        foundItem.after = foundItem.after ? `${foundItem.after}${after ? `, ${after}` : ''}` : after || '';

        foundItem.objectLogs = [
            ...(foundItem.objectLogs ?? []),
            ...lineItemLog.objectsLogs.map((change) => ({
                id: uuidv4(),
                name: change.objectName,
                before: change.changes.reduce(
                    (acc, changeDetail) =>
                        // eslint-disable-next-line no-nested-ternary
                        acc ? `${acc}, ${changeDetail.oldValue}` : changeDetail.oldValue ? String(changeDetail.oldValue) : '',
                    ''
                ),
                after: change.changes.reduce(
                    (acc, changeDetail) =>
                        // eslint-disable-next-line no-nested-ternary
                        acc ? `${acc}, ${changeDetail.newValue}` : changeDetail.newValue ? String(changeDetail.newValue) : '',
                    ''
                ),
                activity: '',
                user: '',
                dateUpdated: ''
            }))
        ];

        return foundItem;
    });

    const logsRowDifferentIds = logsRow.map((item, i) => ({
        ...item,
        id: `${item.id}`
    }));

    return logsRowDifferentIds as unknown as LineItemLogRow[];
};

export const getLogsNames = (items: LineItemLog[] | AuditLog[]) => [...new Set(items?.map((item) => item?.user?.name))];
