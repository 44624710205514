import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { HighlightOff } from '@mui/icons-material';
import { AuditLog } from '../types';
import { mapRecordLogToAuditLogTable } from '../utils';
import { LogReportMode } from '../PropertiesPanel/utils';
import { historyReportColumns } from './detailDialogColumns';
import { DataGridPro } from '@mui/x-data-grid-pro';

export type DetailsDialogProps = {
    item: AuditLog | null;
    onClose: () => void;
    logReportMode: LogReportMode;
};

export const DetailsDialog = ({ onClose, item, logReportMode }: DetailsDialogProps) => (
    <Dialog open={!!item} onClose={onClose} maxWidth="xl">
        {!!item && (
            <>
                <DialogTitle
                    id="form-dialog-title"
                    sx={{
                        fontSize: '24px',
                        fontWeight: '500',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingBottom: 0
                    }}
                >
                    <Box>
                        Data <FormattedMessage id={`title-${item.action}`} />
                    </Box>
                    <IconButton onClick={onClose}>
                        <HighlightOff sx={(theme) => ({ color: theme.palette.secondary.main })} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: '300px' }}>
                        <DataGridPro
                            columns={historyReportColumns}
                            rows={mapRecordLogToAuditLogTable(item, logReportMode)}
                            getRowHeight={() => 'auto'}
                            onCellKeyDown={(_params, event) => {
                                event.defaultMuiPrevented = true;
                            }}
                            sx={{
                                '& .MuiDataGrid-virtualScroller': { marginTop: '0 !important', height: '300px !important' },
                                '& .MuiDataGrid-row': {
                                    alignItems: 'center',
                                    border: 'solid 1px #dddddd',
                                    borderRight: 'none',
                                    borderLeft: 'none',
                                    py: 1
                                },
                                '&.MuiDataGrid-root .MuiDataGrid-cell': { outlineColor: 'transparent !important', outlineWidth: 0 },
                                '&.MuiDataGrid-root .MuiDataGrid-cell:focus': { outlineColor: 'transparent !important', outlineWidth: 0 }
                            }}
                            disableRowSelectionOnClick
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            hideFooter
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ pr: 2.5 }}>
                    <Button variant="contained" color="secondary" onClick={onClose} size="small">
                        Done
                    </Button>
                </DialogActions>
            </>
        )}
    </Dialog>
);

export default DetailsDialog;
