import { ExpandMore, Menu, Close as CloseIcon } from '@mui/icons-material';
import {
    Box,
    Button,
    ButtonGroup,
    ClickAwayListener,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Popper
} from '@mui/material';
import { MouseEvent, useState } from 'react';
import Transitions from 'ui-component/extended/Transitions';
import { ButtonTypeLog, LogReportMode } from '../../utils';
import { SearchBar } from 'ui-component/SearchBar';
import { LogReportType } from 'ui-component/RecordView/types';

export type LogReportSelectorProps = {
    onChangeSelection: (newSelection: LogReportMode) => void;
    setButtonTypeLog?: (newSelection: LogReportType) => void;
    buttonTypeLog: LogReportType;
    toggleLogReport?: () => void;
    setSearchInput?: (value: string) => void;
    searchInput?: string;
};

export const LogReportSelector = ({
    onChangeSelection,
    setButtonTypeLog,
    buttonTypeLog,
    toggleLogReport,
    setSearchInput,
    searchInput
}: LogReportSelectorProps) => {
    const [anchorRef, setAnchorRef] = useState<HTMLElement | null>(null);

    const isOpenSelector = !!anchorRef;

    const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
        setAnchorRef(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorRef(null);
        toggleLogReport?.();
    };

    const handleChangeSelection = (newSelection: LogReportMode) => () => {
        onChangeSelection(newSelection);
        setAnchorRef(null);
    };

    return (
        <Box
            id="logReportSelectorId"
            sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',

                alignItems: 'center',
                padding: '5px',
                bgcolor: '#F5F6F7'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginBottom: '10px'
                }}
            >
                <ButtonGroup
                    sx={{
                        borderRadius: 8,
                        height: 40
                    }}
                    color="primary"
                    aria-label="Logs"
                    component="div"
                >
                    <Button
                        onClick={(e) => setButtonTypeLog?.(ButtonTypeLog.Detail)}
                        type="button"
                        variant={buttonTypeLog === ButtonTypeLog.Detail ? 'contained' : 'text'}
                        color="secondary"
                        size="large"
                        sx={buttonTypeLog === ButtonTypeLog.Detail ? {} : { bgcolor: 'white' }}
                    >
                        Detail
                    </Button>
                    <Button
                        onClick={(e) => setButtonTypeLog?.(ButtonTypeLog.Summary)}
                        type="button"
                        variant={buttonTypeLog === ButtonTypeLog.Summary ? 'contained' : 'text'}
                        color="secondary"
                        size="large"
                        sx={buttonTypeLog === ButtonTypeLog.Summary ? {} : { bgcolor: 'white' }}
                    >
                        Summary
                    </Button>
                </ButtonGroup>

                <IconButton
                    sx={{
                        bgcolor: 'white',
                        width: 40,
                        height: 40
                    }}
                    color="default"
                    size="small"
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                }}
            >
                <SearchBar
                    value={searchInput}
                    placeholder="Find in current page"
                    size="small"
                    InputProps={{ sx: { bgcolor: 'white' } }}
                    onChange={(e) => setSearchInput?.(e.target.value)}
                    hideKeys
                />
                <Button
                    size="medium"
                    color="primary"
                    onClick={handleOpen}
                    sx={(theme) => ({
                        color: theme.palette.primary[400],
                        backgroundColor: '#FFF',
                        fontWeight: 600,
                        borderRadius: '30px',
                        '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                    })}
                >
                    <Menu fontSize="medium" />
                    <ExpandMore fontSize="medium" />
                </Button>
                <Popper
                    onResize={null}
                    onResizeCapture={null}
                    placement="bottom-end"
                    open={isOpenSelector}
                    anchorEl={anchorRef}
                    role={undefined}
                    transition
                    disablePortal
                    sx={{ border: `1px solid #D9D9D9`, borderRadius: '8px !important', zIndex: 9 }}
                    popperOptions={{
                        modifiers: [{ name: 'offset', options: { offset: [0, 14] } }]
                    }}
                >
                    {({ TransitionProps }) => (
                        <ClickAwayListener onClickAway={handleClose}>
                            <Transitions in={isOpenSelector} {...TransitionProps}>
                                <Paper sx={{ borderRadius: '8px !important' }}>
                                    {isOpenSelector && (
                                        <List>
                                            <ListItem divider disablePadding>
                                                <ListItemButton
                                                    sx={(theme) => ({
                                                        '&:hover': {
                                                            color: theme.palette.primary[400],
                                                            background: theme.palette.primary[300]
                                                        }
                                                    })}
                                                    onClick={handleChangeSelection(LogReportMode.All)}
                                                >
                                                    <ListItemText primary="Record History" />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem divider disablePadding>
                                                <ListItemButton
                                                    sx={(theme) => ({
                                                        '&:hover': {
                                                            color: theme.palette.primary[400],
                                                            background: theme.palette.primary[300]
                                                        }
                                                    })}
                                                    onClick={handleChangeSelection(LogReportMode.Objects)}
                                                >
                                                    <ListItemText primary="Objects History" />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem divider disablePadding>
                                                <ListItemButton
                                                    sx={(theme) => ({
                                                        '&:hover': {
                                                            color: theme.palette.primary[400],
                                                            background: theme.palette.primary[300]
                                                        }
                                                    })}
                                                    onClick={handleChangeSelection(LogReportMode.LineItems)}
                                                >
                                                    <ListItemText primary="Line items History" />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton
                                                    sx={(theme) => ({
                                                        '&:hover': {
                                                            color: theme.palette.primary[400],
                                                            background: theme.palette.primary[300]
                                                        }
                                                    })}
                                                    onClick={handleChangeSelection(LogReportMode.Comments)}
                                                >
                                                    <ListItemText primary="Comments History" />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    )}
                                </Paper>
                            </Transitions>
                        </ClickAwayListener>
                    )}
                </Popper>
            </Box>
        </Box>
    );
};
