import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LineItemLog, LineItemLogRow } from 'ui-component/records/LineItems/types';
import {
    getLogsNames,
    mapRecordLogToLineItemAuditLogTableByName,
    mapRecordLogToObjectsLogTableByName
} from 'ui-component/records/LineItems/utils';
import { SkeletonLoaderList } from 'ui-component/RecordView/PropertiesPanel/LogReportPanel/components';
import { extractTextFromJson } from 'views/DocumentViewer/utils';
import { AuditLog } from 'ui-component/RecordView/types';
import { LogReportMode } from 'ui-component/RecordView/PropertiesPanel/utils';

type LogItemDetailProps = {
    record: LineItemLogRow;
};

const LogItemDetail: FC<LogItemDetailProps> = ({ record }) => {
    const [show, setShow] = useState(false);

    // record?.id is the name of the item
    return (
        <Box>
            <Box
                sx={{
                    paddingX: 1,
                    paddingY: 1,
                    bgcolor: 'white',
                    marginBottom: 0.2
                }}
            >
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'black', fontSize: 12 }} component="div">
                    {record?.id}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '45%'
                        }}
                    >
                        <Box
                            sx={{
                                color: '#EF5350',
                                fontWeight: 400,
                                fontSize: 12,
                                letterSpacing: 0.4
                            }}
                        >
                            <Tooltip title={extractTextFromJson(record.before ?? '', '')}>
                                <Box>{extractTextFromJson(record.before ?? '', '--')}</Box>
                            </Tooltip>
                        </Box>
                        <Box>
                            <ArrowForwardIcon fontSize="small" />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '45%'
                        }}
                    >
                        <Box
                            sx={{
                                fontWeight: 400,
                                fontSize: 12,
                                letterSpacing: 0.4
                            }}
                        >
                            <Tooltip title={extractTextFromJson(record.after ?? '', '')}>
                                <Box>{extractTextFromJson(record.after ?? '', '--')}</Box>
                            </Tooltip>
                        </Box>
                    </Box>

                    <Box width="10%">
                        <IconButton onClick={() => setShow((previous) => !previous)}>
                            {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                    </Box>
                </Box>
            </Box>

            {show && (
                <Box
                    display="flex"
                    flexDirection="row"
                    sx={{
                        paddingX: 3,
                        paddingY: 1,
                        marginBottom: 1,
                        bgcolor: '#EBEEFE'
                    }}
                >
                    <Box width="92%">
                        <Box>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: 12, color: 'black' }} component="div">
                                {record.id}
                            </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <Box width="100%">
                                {record.objectLogs?.map((objectLog) => (
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        sx={{
                                            marginBottom: 1
                                        }}
                                        key={objectLog.id}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            width="50%"
                                        >
                                            <Tooltip title={objectLog.name}>
                                                <Box width="50%" sx={{ wordBreak: 'break-word' }}>
                                                    {objectLog?.name || ''}:
                                                </Box>
                                            </Tooltip>
                                            <Box
                                                sx={{
                                                    color: '#EF5350',
                                                    textAlign: 'left',
                                                    width: '50%'
                                                }}
                                            >
                                                <Tooltip title={extractTextFromJson(record.before ?? '', '')}>
                                                    <Box width="50%">{extractTextFromJson(record.before ?? '', '--')}</Box>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" width="50%">
                                            <ArrowForwardIcon fontSize="small" />
                                            <Tooltip title={extractTextFromJson(record.after ?? '', '')}>
                                                <Box width="50%">{extractTextFromJson(record.after ?? '', '--')}</Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" textAlign="right" width="8%">
                        <IconButton onClick={() => setShow((previous) => !previous)}>
                            <KeyboardArrowUpIcon />
                        </IconButton>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

type Props = {
    items: LineItemLog[] | AuditLog[];
    loading: boolean;
    logReportMode: LogReportMode;
};

const DetailHistoryItemName: FC<Props> = ({ items, loading, logReportMode }) => {
    const [logNames, setLogNames] = useState<string[]>();

    useEffect(() => {
        if (items) {
            const names = getLogsNames(items);
            setLogNames(names);
        }
    }, [items]);

    const result = useMemo(
        () =>
            logNames?.map((name) => {
                const recordsById =
                    logReportMode === LogReportMode.Objects
                        ? mapRecordLogToObjectsLogTableByName(name, items as AuditLog[])
                        : mapRecordLogToLineItemAuditLogTableByName(name, items);

                return recordsById?.map((record, i) => <LogItemDetail key={i} record={record} />);
            }),
        [items, logNames, logReportMode]
    );

    if (loading) return <SkeletonLoaderList />;

    if (!items?.length) {
        return (
            <Typography align="center" variant="caption" sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                No more data to load.
            </Typography>
        );
    }

    return <Box>{result}</Box>;
};

export default DetailHistoryItemName;
