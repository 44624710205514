import { GridValueGetterParams } from '@mui/x-data-grid-pro';
import { IRecordFieldsList } from '../types';

export const valueGetterList: Record<keyof IRecordFieldsList, (params: GridValueGetterParams) => string> = {
    tenant: (params) => params.row.tenant?.name || '',
    statusId: (params) => params.value?.name || '',
    problemCodeId: (params) => params.value?.name || '',
    assignedTo: (params) => params.value?.name || '',
    receivedBy: (params) => params.value?.name || '',
    approvedBy: (params) => params.value?.name || '',
    recordType: (params) => params.value?.name || '',
    createdBy: (params) => params.value?.name || '',
    targetTransmissionTenant: (params) => params.value?.name || '',
    businessEntity: (params) => params.value?.displayName || ''
};

export const getValueGetter = (key: keyof IRecordFieldsList) => valueGetterList[key];
