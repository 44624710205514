import { gql } from '@apollo/client';

export const QUERY_GET_COMMENTS_LOGS = gql`
    query FilterCommentLogsByRecordHeader($data: GetCommentLogsByRecordHeaderInputPaginated!) {
        filterCommentLogsByRecordHeader(data: $data) {
            logs {
                action
                changes {
                    what
                    newValue
                    oldValue
                }
                commentId
                datetime
                user {
                    id
                    name
                    firstName
                    lastName
                }
            }
        }
    }
`;
